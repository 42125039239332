import styled from "styled-components";

export const Container = styled.div`
  max-width: 730px;

  @media (max-width: 768px) {
    max-width: 272px;
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 13px;

  @media (max-width: 768px) {
    margin-top: 18px;
  }
`;
