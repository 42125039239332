import {
  Container,
  Content,
  ContentBox,
  Media,
  Paragraph,
  StyledImg,
} from "./styles";
import React from "react";
import { useImage } from "@queries/use-image";

const AboutIntroComponent = () => {
  const { getFluidImageNameAndExtension } = useImage();
  return (
    <Container>
      <ContentBox>
        <Content>
          <Paragraph>
            Somos um grupo de jovens (na sua maioria, cientistas e engenheiros)
            que pretende organizar para os alunos do Ensino Básico ao Superior
            aquilo que nós gostávamos de ter feito quando éramos mais novos.
          </Paragraph>
          <Paragraph>
            ​​Desde 2015, organizamos iniciativas para alunos curiosos e
            motivados, ajudando-os a definir e levar a cabo os seus próprios
            projectos de aprendizagem e exploração.
          </Paragraph>
        </Content>
      </ContentBox>

      <Media>
        <StyledImg
          fluid={getFluidImageNameAndExtension("orange-mountain-people", "jpg")}
          alt="Pessoas em cima da montanha"
        />
      </Media>
    </Container>
  );
};

export default AboutIntroComponent;
