import { graphql, useStaticQuery } from "gatsby";
import { isValidString } from "@utilities/string";

export const useLeadingMembers = () => {
  const data = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: {
            relativeDirectory: { eq: "leading-members" }
            sourceInstanceName: { eq: "images" }
          }
        ) {
          nodes {
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    `
  );

  const getMemberFluidImage = (name) => {
    if (data.images?.nodes.length === 0) {
      return null;
    }
    // NOTE: The search for the image by name, in this case, is Case Sensitive
    const image = data.images?.nodes.find((node) => node.name === name);

    if (image === undefined || !isValidString(name)) {
      return data.images?.nodes.find((node) => node.name === "empty-profile")
        .childImageSharp?.fluid;
    }

    return image?.childImageSharp?.fluid || null;
  };

  return {
    getMemberFluidImage,
  };
};
