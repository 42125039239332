import { Container, Paragraph, Title } from "./styles";
import React from "react";

const AboutHeroContentComponent = () => {
  return (
    <Container>
      <Title>Sobre nós</Title>
      <Paragraph>
        O TreeTree2 é uma organização sem fins lucrativos que pretende cumprir o
        potencial criativo e intelectual dos jovens.
      </Paragraph>
    </Container>
  );
};

export default AboutHeroContentComponent;
