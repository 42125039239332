import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 40px;
  justify-items: center;

  @media (max-width: 768px) {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(158px, 1fr));
  }
`;

export const Profile = styled.div`
  width: 100%;
  min-width: 158px;
  max-width: 255px;
`;

export const FullName = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000;
  margin-top: 30px;
  width: 100%;
`;

export const Excerpt = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 7px;
  width: 100%;
`;
