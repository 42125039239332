import Img from "gatsby-image";
import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  max-height: 314px;

  @media (max-width: 768px) {
    flex-direction: column;
    max-height: unset;
  }
`;

export const ContentBox = styled.div`
  flex: 1;
  max-width: 569px;

  @media (max-width: 768px) {
    max-width: unset;
    padding-right: 0;
  }
`;

export const Content = styled.div`
  max-width: 350px;

  @media (max-width: 768px) {
    max-width: unset;
  }
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
  & + & {
    margin-top: 11px;
  }
`;

export const Media = styled.div`
  flex: 1;
  max-width: 541px;

  @media (max-width: 768px) {
    max-width: unset;
    margin-top: 30px;
    height: 275px;
  }
`;

export const StyledImg = styled(Img)`
  height: 100%;
  border-radius: 5px;

  @media (max-width: 768px) {
    height: 275px;
  }
`;
