export const members = [
  {
    fullname: "João Rico",
    excerpt: "Físico. Aluno PhD em Machine Learning",
    imageFilename: "joao-rico",
  },
  {
    fullname: "Pedro Marcelino",
    excerpt: "Eng. Civil. Aluno PhD em Machine Learning",
    imageFilename: "pedro-marcelino",
  },
  {
    fullname: "João Coelho",
    excerpt: "Físico. Cientista de Dados",
    imageFilename: "",
  },
  {
    fullname: "Virgínia Barbosa",
    excerpt: "Engenheira Informática. Realizadora de Cinema",
    imageFilename: "virginia-barbosa",
  },
  {
    fullname: "Francisco Gonçalves",
    excerpt: "Psicólogo. Aluno PhD em Psicologia e Criminologia",
    imageFilename: "",
  },
];
