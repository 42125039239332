import { Container, Excerpt, FullName, Profile } from "./styles";
import Img from "gatsby-image";
import React from "react";
import { members } from "./data";
import { useLeadingMembers } from "@queries/use-leading-members";

const LeadingMembers = () => {
  const { getMemberFluidImage } = useLeadingMembers();

  if (members.length === 0) {
    return null;
  }

  return (
    <Container>
      {members.map((member) => {
        const { fullname, excerpt, imageFilename } = member;
        return (
          <Profile key={String(fullname).split(" ").join("")}>
            <Img fluid={getMemberFluidImage(imageFilename)} />
            <FullName>{fullname}</FullName>
            <Excerpt>{excerpt}</Excerpt>
          </Profile>
        );
      })}
    </Container>
  );
};

export default LeadingMembers;
