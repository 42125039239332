import AboutHeroContentComponent from "@sections/about-hero-content/about-hero-content-component";
import AboutIntro from "@sections/about-intro/about-intro-component";
import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Hero from "@components/hero";
import Layout from "@components/layout";
import LeadingMembers from "@components/leading-members/leading-members-component";
import Newsletter from "@components/newsletter/newsletter-component";
import React from "react";
import Seo from "@components/seo";
import { useImage } from "@queries/use-image";

export default function About() {
  const { getFluidImageNameAndExtension } = useImage();
  const fluidHero = getFluidImageNameAndExtension("home-background", "jpg");
  const seoImage = getFluidImageNameAndExtension(
    "people-in-public-cafe",
    "jpg"
  );

  return (
    <Layout isHeroAvailable={true}>
      <FullBleed>
        <Hero fluidImage={fluidHero}>
          <AboutHeroContentComponent />
        </Hero>
      </FullBleed>

      <EmptySpace desktop={{ margin: 160 }} mobile={{ margin: 60 }} />

      <AboutIntro />

      <EmptySpace desktop={{ margin: 150 }} mobile={{ margin: 60 }} />

      <LeadingMembers />

      <EmptySpace desktop={{ margin: 140 }} mobile={{ margin: 60 }} />

      <FullBleed>
        <Newsletter />
      </FullBleed>

      <Seo
        title="Descobre o TreeTree2"
        description="Somos um grupo de cientistas, engenheiros e médicos que pretende organizar para os alunos do Ensino Básico ao Superior aquilo que nós gostávamos de ter feito quando éramos mais novos."
        image={seoImage}
      />
    </Layout>
  );
}
